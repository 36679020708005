import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'

export const Protected = () => {
  return <Outlet />
}

export const Route = createFileRoute('/_protected')({
  beforeLoad: async ({ context }) => {
    const isLoggedIn = await context.auth.strategy?.isLoggedIn();

    if (!isLoggedIn) {
      throw redirect({ to: '/' })
    }
  },
  component: () => <Protected />
})
