import React from 'react'
import { AuthProviderProps, IAuthProvider, User } from '@lib/auth/AuthProvider'

export interface AuthStrategy {
  login: () => void
  logout: () => void
  getUser: () => Promise<User | undefined>
  isLoggedIn: () => Promise<boolean>
}

interface AuthContextValue {
  strategy: AuthStrategy | undefined
  setAuthStrategy: ((authStrategy: AuthStrategy) => void) | undefined
}

const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue)

export const useAuth = () => {
  const auth = React.useContext(AuthContext)
  if (auth === null) {
    throw new Error('Unable to get context, it must be used in a child of an AuthProvider')
  }

  return auth;
}

export const createAuth = (authConfig: IAuthProvider) => {
  const { getUser, login, logout } = authConfig;
  const getUserFn = () => getUser?.()
  const logoutFn = () => logout?.()
  const loginFn = () => login?.()
  const isLoggedInFn = async() => {
    return !! await getUser?.();
  }

  return { getUser: getUserFn, login: loginFn, logout: logoutFn, isLoggedIn: isLoggedInFn }
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const value: AuthContextValue = {
    strategy: undefined,
    setAuthStrategy: undefined
  }
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}