const getApiUrl = (route: string): string => {
    const baseUrl = import.meta.env.VITE_API_ENDPOINT;
    return `${baseUrl}${route}`
}

export async function isValidToken(token: string | undefined): Promise<boolean> {
    try {
        const url = getApiUrl('/auth/version');
        const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` }
        });

        const version = await response.json();
        return true;
    } catch (error) {
        return false;
    }
}