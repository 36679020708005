import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from '@/routeTree.gen'
import { createAuth, useAuth } from '@lib/auth/useAuth'
import { getDefaultAuthProvider } from '@lib/auth/AuthProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    auth: undefined!
  }
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

function App(){
  const queryClient = new QueryClient();
  const provider = getDefaultAuthProvider();
  const authStrategy = createAuth(provider);
  const auth = useAuth();
  auth.strategy = authStrategy;

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={router}
        context={{ auth }}
      />
    </QueryClientProvider>
  )
}

export default App
