import { ReactElement } from 'react'
import { Card } from 'react-daisyui'
import OAuthProviderButton from '@pages/Login/Components/OAuthProviderButton'
import { pointClickCareAuthProvider } from '@lib/auth/authProviders/pointClickCareAuthProvider'

interface LoginProps {}

const Login = (props: LoginProps): ReactElement => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <Card className='p-4 border border-grey-200 rounded-lg flex items-center shadow-md'>
        <div className='flex flex-col'>
          <Card.Image
            className='pb-10 pl-20 pr-20 pt-5'
            src='img/curitecjpg-300x101.jpg'
            alt='curitec-logo'
          />
          <Card.Body className='w-full flex flex-col items-center space-y-2'>
            <p className='text-curitec-green font-semibold'>Select Your EMR</p>
            <div className='w-full pb-20 px-10'>
              <ul className='space-y-2'>
                <li>
                  {/* move provider properties to config file and map over array,
                  or move to backend and jsut send backend provider name and let it build
                  the url then redirect the browser with a 303 to the url we build below in
                  the OAuthProviderButton*/}
                  <OAuthProviderButton authProvider={pointClickCareAuthProvider()}>PointClickCare</OAuthProviderButton>
                </li>
                <li>{/*<OAuthProviderButton providerName='Epic'>Epic</OAuthProviderButton>*/}</li>
              </ul>
            </div>
          </Card.Body>
        </div>
      </Card>
    </div>
  )
}

export default Login
