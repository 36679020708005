import { createRootRouteWithContext, Navigate, Outlet, redirect, useRouter } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useAuth } from '@lib/auth/useAuth'

interface RouterContext {
  auth: ReturnType<typeof useAuth>
}

const Root = () => {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => {
    return <Root />
  }
})
