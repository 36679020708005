import { pointClickCareAuthProvider } from "@lib/auth/authProviders/pointClickCareAuthProvider"

export type User = {
  firstName: string
  lastName: string
  sub: string
  aud: string
  iss: string
  exp: number
  iat: number
  email: string
}

export interface AuthProviderProps {
  children: React.ReactNode
}

type Provider = {
  name: string
  code: string
}

type SupportedProviders = 'pointClickCare'

export type Providers = {
  [K in SupportedProviders]: Provider
}

const providerOptions: Providers = {
  pointClickCare: {
    name: 'PointClickCare',
    code: 'pcc'
  }
}

export interface OAuthProps {
  provider: keyof Providers
  loginUrl: URL
}


export interface IAuthProvider {
  login: () => void
  logout: () => void
  getUser: () => Promise<User | undefined>
  getToken: () => string | null
}

export const getDefaultAuthProvider = (): IAuthProvider => {
  // In case we have multiple providers, we can add here the logic to discover the current provider that is been used
  return pointClickCareAuthProvider();
}
