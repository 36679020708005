import { IAuthProvider, User } from '../AuthProvider'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { isValidToken } from '@services/ApiService'

export const pointClickCareAuthProvider = (): IAuthProvider => {

  const cookieName = "id_token";
  const jwtStorageKey = "jwt";

  const clearStorage = (): void => {
    localStorage.clear();
    const domainName = window.location.hostname;
    Cookies.remove(cookieName, { path: '/', domain: `.${domainName}` });
  };

  return {
    getUser: async () => {
      const cookie = Cookies.get(cookieName);
      let token = localStorage.getItem(jwtStorageKey);

      if (cookie) {
        clearStorage();
        const isValid = await isValidToken(cookie);

        if (!isValid) {
          return;
        }

        localStorage.setItem(jwtStorageKey, token = cookie);
      }

      if (!token)
        return;

      const decoded = jwtDecode<User>(<string>token);
      if (Date.now() >= decoded.exp * 1000)
        return;

      return decoded;
    },
    login: () => {
      const baseUrl = import.meta.env.VITE_API_ENDPOINT;
      window.location.assign(`${baseUrl}/auth/provider?code=pcc`);
    },
    logout: () => {
      clearStorage();
    },
    getToken: () => {
      return localStorage.getItem(jwtStorageKey);
    }
  }
}
