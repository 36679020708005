import { ReactElement } from 'react'
import { ButtonProps } from 'react-daisyui'
import CuritecButton from '@pages/Approvals/Components/CuritecButton'
import { createAuth } from '@lib/auth/useAuth'
import { IAuthProvider } from '@lib/auth/AuthProvider'

const OAuthProviderButton = ({
  authProvider,
  ...rest
}: { authProvider: IAuthProvider } & ButtonProps & Omit<ButtonProps, 'onClick'>): ReactElement => {
  const authFn = createAuth(authProvider);

  return (
    <CuritecButton
      onClick={ () => {
        authFn.login();
      }}
      {...rest}
    />
  )
}

export default OAuthProviderButton
