import { createFileRoute, redirect } from '@tanstack/react-router'
import Login from '@pages/Login'

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context }) => {
    const isLoggedIn = await context.auth.strategy?.isLoggedIn();
    
    console.log(`Is LoggedIn ${isLoggedIn}`);
    if (isLoggedIn) {
      throw redirect({ to: '/approvals' })
    }
  },
  component: () => <Login />
})
