import clsx from 'clsx'
import { Button, ButtonProps } from 'react-daisyui'

const classes = clsx([
  'btn',
  'bg-white',
  'hover:bg-white',
  'hover:shadow-md',
  'border-1',
  'hover:border-curitec-orange',
  'border-curitec-orange',
  'text-curitec-green',
  'hover:text-curitec-orange',
  'w-full'
])

type CuritecButtonProps = ButtonProps

const CuritecButton = (props: CuritecButtonProps) => {
  return (
    <Button
      className={classes}
      {...props}
    />
  )
}

export default CuritecButton
